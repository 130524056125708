import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  FormHelperText

} from "@mui/material"; 
import React, { useEffect, useState } from "react";
// import { filtersQuery } from "../../../utils";
import Loader from '../../../components/Loader';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
// import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
// import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
// import Loader from '../../../components/Loader';
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TemplateModal } from "./TemplateModal";
import { ApiService } from "../../../services/ApiService";
import { getLocalData } from "../../../utils";

const CampaignDetail = ({ setFormData, formData, errors,getListData }) => {
  const franchiseeId = getLocalData('franchiseeId');
  const locationId = getLocalData("locationId");
  const [createTemplate, setCreateTemplate] = useState(false);
  const [cohortsList, setCohortsList] = useState([]);
  const [templatesList, setTemplateList] = useState([]);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('')
  const [getFranchisee, setFranchisee] = useState([]); 


  const [templateErr, setTemplateErr] = useState({
    templateName: '',
    templateMessage: '',
    templateDescription: '',
    tempExitsError : ''

  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
 // const [CampNameExistsErr, setCampNameExistsErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [templateValues, setTemplateValues] = useState({
    templateName: '',
    templateMessage: '',
    templateDescription: '',
    tempExitsError : ''

  });
 console.log('getListDataData',formData,getListData)
console.log('getListDataformData',formData);
  // useEffect(() => {
  //   getCampaignList();
  // }, []);
  



  const getCohortsList = async () => {
    setLoading(true)

    try{
    let encodedFranchiseeId = encodeURIComponent(franchiseeId)
    let filtersParams =  "";
      const response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllFranchiseeCohorts?${filtersParams}&franchiseeId=${encodedFranchiseeId}`
      )
      let Items =
        response && response?.data && response?.data?.Items
          ? response?.data?.Items
          : [];
          setCohortsList(Items);     
          setLoading(false)
 
   }catch(err){
      console.log(err) 
      setLoading(false)

    }

  };

  const handleTemplate = (value) => {
    errors.senderId = '';
    errors.selectedTemplate = '';
    errors.selectedCohort = '';
    formData.senderId = '';
    formData.selectedCohort = '';
    formData.selectedTemplate = '';
    setFormData({ ...formData, selectedChannel: value ,selectedMessageType:'Queued'});
    getTemplatesList(value);

  };

 

  const getTemplatesList = async (value) => {
    setLoading(true)
    let encodedFranchiseeId = encodeURIComponent(franchiseeId)
    // let response = await ApiService.get(
    //   `${process.env.REACT_APP_API_END_POINT}pinpoint/listTemplates?type=${value}`
    // );
    let response = await ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllFranchiseeTemplates?channel=${value}&franchiseeId=${encodedFranchiseeId}`
    );

    let Item =
      response && response?.data && response?.data?.Items
        ? response?.data?.Items
        : [];
        setLoading(false)
    setTemplateList(Item);
  };

  useEffect(() => {
    getCohortsList();
    getTemplatesList(formData.selectedChannel.toUpperCase());
  }, [formData.selectedChannel, createTemplate,]);

 
  const getCampaignDetails = async () => {
    setLoading(true)
    let encodedLocationId = encodeURIComponent(locationId);
    let Id = encodeURIComponent("08363f8f2e4340cea64666c6e145ddee");
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getCampaignDetails?locationId=${encodedLocationId}&id=${Id}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      console.log(Item, "deleted"); 
      setLoading(false)

    } catch (e) {

      console.log(e, "error");
      setLoading(false)
    }

  };

  console.log('listtemp', templatesList,getCampaignDetails)


  const getFranchiseeName = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}franchisees/getFranchiseeDetail?franchiseeId=${encodedFranchiseeId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
          setFranchisee(Item.franchiseeName)
     
    } catch (error) {
    
      console.log(error);
    }
  };
  console.log(getFranchisee,'Item');
  useEffect(() => {
    getFranchiseeName();
    
  }, []);

  const createTemplateApi = async () => {

    setIsLoading(true)

      // Construct payload for SMS
      const payload  = {

        locationId: locationId || '',
        franchiseeId: franchiseeId || '',
        TemplateType: formData.selectedChannel.toUpperCase() || '',
        TemplateName: templateValues.templateName || '',
        Version: formData.Version || '1',
        createdBy: getFranchisee,
        TemplateDescription: templateValues.templateDescription || 'Description',
        Body: templateValues.templateMessage
      } 

    try {
   
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/duplicateTemplates`,
        payload

      );
      setErrorMessage('')
      setShowErrorMessage(false)
      if (response?.statusCode === 200) {

        setIsLoading(false)
        setCreateTemplate(false);
        setShowSuccessMessage(true);
        setSuccessMessage('Template created successfully')

      }else{

        setIsLoading(false)
        setShowErrorMessage(true)
        setErrorMessage(response?.message || 'Something went wrong, try later')

      }

 

    } catch (e) {

      setIsLoading(false)
      setShowErrorMessage(true)    
      setErrorMessage('Something went wrong, try later')
      console.log(e, "error");

    }

  };

  const closePopup = () => {
    setShowSuccessMessage(false);
   // setCreateTemplate(false)
    getTemplatesList(formData.selectedChannel.toUpperCase());

  };

 
  const handleTemplateSubmit = (type) => {

    if (type === "save") {

      const newerrors = {};
       if (!templateValues.templateName) {
        newerrors.templateName = 'Template name is required';
      }

      if (!templateValues.templateMessage) {
        newerrors.templateMessage = 'Template message is required';
      }
    
      if (Object.keys(newerrors).length > 0) {
        setTemplateErr(newerrors);
        return;
      }
     createTemplateApi();

    } else {

      templateErr.templateName = '';
      templateErr.templateMessage = '';
      templateErr.tempExitsError = '';
      templateValues.templateName = ''
      templateValues.templateMessage = ''
      templateValues.templateDescription = ''
      templateValues.tempExitsError = ''
      setShowErrorMessage(false)  
     // setTemplateExistsError('')
      setErrorMessage('')
      setCreateTemplate(false);

    }

  };

  const handleCohortChange = (value) => {

   // const { name, value } = e.target;
    const list = [...cohortsList];
    const filteredValue = list.filter((item) => item.sortKey === value)[0];
    console.log(filteredValue,'filteredValuefilteredValue');
      setFormData({ ...formData, selectedCohort: value, selectedCohortName: filteredValue.Name,selectedSegment:filteredValue?.Segments });
    if(errors['selectedCohort']){
      errors.selectedCohort = '';
   }

  };

 

  const handleCampaignNameChange = (e) => {
   const { name,value } = e.target;    
    setFormData({ ...formData, campaignName: value });  
    if (errors[name]) {
      errors.campaignName = '';      
  }

  };
  // const checkCampaignNameExists = (e) => {
  //  const { name,value } = e.target;    
  //   setFormData({ ...formData, campaignName: value });  
  //  // getCampaignList(value)  
  //   if (errors[name]) {
  //     errors.campaignName = '';      
  // }

  // };

 

  const handleSenderIdChange = (value) => {
    console.log(value)
   setFormData({ ...formData, senderId: value });
   // const senderIdPattern = /^[a-zA-Z0-9\-_.]{0,11}$/; 
      if (errors['senderId']) {
       errors.senderId = '';      
   }

  };

  const handlTemplateOpen = () => {

    templateValues.tempExitsError = ''
    templateErr.tempExitsError= ''
    setTemplateValues({
      templateName : '',
        templateMessage : '',  
        templateDescription : '',  
        tempExitsError : ''  
      })
   setCreateTemplate(true)

  };

 

  // method to handle template selection

  const handleTemplateChange = (value) => {

    setTemplateValues({
    templateName : '',
      templateMessage : '',  
      templateDescription : '', 
      tempExitsError : ''  
    })

    templateErr.templateName = '';
    setFormData({
      ...formData,
      selectedTemplate: value
    })    
    if (errors['selectedTemplate']) {
      errors.selectedTemplate = '';
      }

  };

 
  return (
    <>
        <Loader IsLoading={loading} />
     {showSucessMessage &&(
   <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
        )}

      <Box className={"campaign-form"}>
        <Box className="campaign-form-section">
          <TextField
            name="campaignName"
            sx={{
              width: "50%"
            }}

            id="outlined-required"
            label="CAMPAIGN NAME *"
            className="text-field-styling input-field-styling"
            value={formData.campaignName}
            onChange={handleCampaignNameChange}
           // onKeyUp={checkCampaignNameExists}
            error={!!errors.campaignName }
            helperText={errors.campaignName}
            InputLabelProps={{
              className: "label-style-text-field label-style-create-franchisee"

            }}

          />

          <Box className={"form-radio"}>

            {/*  Radio buttons  with label */}

            <Typography className="blue-heading">Channel *</Typography>
            <Box>
              <FormControl className="custom-radio-group">
                <RadioGroup
                 defaultValue="EMAIL"
                  name="radio-buttons-group"
                  onChange={(e) => handleTemplate(e.target.value)}
                  value={formData.selectedChannel?.toUpperCase()}

                >

                  <FormControlLabel
                    value="EMAIL"
                    control={<Radio />}
                    label="Email"
                    labelPlacement="end"

                  />

                  {/* <FormControlLabel
                    value="SMS"
                    control={<Radio />}
                    label="SMS"
                    labelPlacement="end"

                  /> */}
                </RadioGroup>

              </FormControl>

            </Box>

          </Box>

          <Box className={"form-item"}>
            <FormControl
              pt={2}
              variant="outlined"
              fullWidth
              sx={{
                width: "50%"
              }}

              error={!!errors.selectedCohort}
            >
               <InputLabel
                   id="demo-simple-select-helper-label"
                   className="input-field-dropdown-styling "
                >  SELECT COHORT * </InputLabel>

              <Select
                value={formData.selectedCohort}

                onChange={(event) =>
                  handleCohortChange(event.target.value)
                }

                label="SELECT COHORT *"
                className="input-field-styling cohort-input-styling"
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{  PaperProps: { style: { maxHeight: 300 } }

                }}

              >
              
                {cohortsList?.length > 0 &&

                  cohortsList.map((item, index) => (
                    <MenuItem key={index} id={index} value={item.sortKey}>
                      {item.Name}
                    </MenuItem>

                  ))}

              </Select>

              {errors.selectedCohort && (
                          <FormHelperText>
                           {errors.selectedCohort}
                          </FormHelperText>
                        )}
            </FormControl>

            <Box sx={{
              width: "50%",

            }}>

              <FormControl

                pt={2}
                sx={{width: "100%", position: "relative"}}
                variant="outlined"
               fullWidth
                error={!!errors.selectedTemplate}

              >

                <InputLabel
                   id="demo-simple-select-helper-label"
                   className="input-field-dropdown-styling "

                >
                   SELECT&nbsp;&nbsp;TEMPLATE *
                </InputLabel>
                <Select
                  value={formData.selectedTemplate}
                 onChange={(event) => handleTemplateChange(event.target.value) }
                  label="SELECT  TEMPLATE *"
                  className="input-field-styling cohort-input-styling"
                  IconComponent={KeyboardArrowDownIcon}
                  MenuProps={{
                    PaperProps: { style: { maxHeight: 300 } }

                  }}

                >

                  {templatesList?.length > 0 &&

                    templatesList.map((item, index) => (

                      <MenuItem key={index} id={index} value={item.TemplateName}>

                        {item.TemplateName}

                      </MenuItem>

                    ))}

                </Select>                                    

              </FormControl>

              {errors.selectedTemplate && (

                          <FormHelperText sx={{position:"absolute"}}>

                           {errors.selectedTemplate}

                          </FormHelperText>

                        )}

                       

              {formData.selectedChannel.toUpperCase() !== "EMAIL" &&

                <Box>

                  <InputLabel

                    sx={{ position: "absolute", mt: 0.5, fontWeight: 600, mr: 1 }}
                    className="template-link"
                    onClick={handlTemplateOpen}
                  >
                    + Create Template
                  </InputLabel>

                </Box>}                        
            </Box>         

          </Box>

         

          <Box className={"form-item"} sx={{mt:1}}>
            {formData.selectedChannel.toUpperCase() !== "EMAIL" ?
              <Box className={"form-radio"}>

                {/*  Radio buttons  with label */}

                <Typography className="gray-heading font-weight-600">Message Type *</Typography>

                <Box>

                  <FormControl className="custom-radio-group">

                    <RadioGroup
                     defaultValue="Queued"
                      name="messageType"
                      onChange={(e) =>
                       setFormData({
                          ...formData,
                         selectedMessageType: e.target.value
                       })

                      }
                      value={formData.selectedMessageType}

                    >
                     <FormControlLabel
                        value="Immediate"
                       control={<Radio />}
                        label="Immediate"
                        labelPlacement="end"

                      />

                      <FormControlLabel
                       value="Queued"
                        control={<Radio />}
                        label="Queued"
                        labelPlacement="end"
                        defaultValue="Queued"

                      />

                    </RadioGroup>
                  </FormControl>
                </Box>

              </Box>

              : <TextField
                name="campaignName"
                sx={{
                  width: "50%"
                }}
                id="outlined-required"

                label={"SENDER NAME"}
                className="text-field-styling input-field-styling"
                value={formData.senderName}
                onChange={(e) =>
                  setFormData({ ...formData, senderName: e.target.value })                
                }
                InputLabelProps={{
                  className: "label-style-create-franchisee edit-address-label"
                }}

              />}

               {formData.selectedChannel.toUpperCase() === "SMS" ? (
                <TextField
                  name="senderId"
                  sx={{
                    width: "50%"
                  }}             
                  id="outlined-required"

                  label="SENDER ID *"

                  className="text-field-styling input-field-styling"
                  value={formData.senderId}              
                  onChange={(event)=>handleSenderIdChange(event.target.value)}

                  error={!!errors.senderId}

                  helperText={
                    errors.senderId
                  }
                  InputLabelProps={{
                   className: "label-style-create-franchisee edit-address-label"

                  }}

                  inputProps={{
                    maxLength: 11 // Set the maxLength here
                  }}
                />) :
                <></>
              }

          </Box>

        </Box>
      </Box>

      <TemplateModal open={createTemplate} handleClose={handleTemplateSubmit}
                     value={templateValues} isLoading={isLoading}
                      errorMessage={errorMessage}
                     showErrorMessage={showErrorMessage}
                     tempError={templateErr}
                     setValues={setTemplateValues}

                     />

    </>

  );

};

export default CampaignDetail;