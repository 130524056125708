/* eslint-disable no-unused-vars */
import './index.css';
import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Divider,
  DialogActions,
  TextField,
  Card,
  Button,
  FormHelperText
} from '@mui/material';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import Loader from '../../../components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { getLocalData } from 'src/utils';
import ConditionBuilder from './ConditionBuilder';
import useCreateCohorts from './Functions/CreateCohortsFunctions';
import { CohortClientTable } from './CohortClientTable';
import debounce from 'lodash/debounce';
import useEditCohorts from './Functions/EditCohortsFunctions';
import { ApiService } from 'src/services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const CreateCohorts = ({
  setShowScreen,
  getFranchisee,
  getCohorts,
  isDuplicate,
  selectedRow
}) => {
  const { getAllAttribute, validateConditions, checkCohortNameExist } =
    useCreateCohorts();
  const { setConditionData } = useEditCohorts();

  const [cohortNameError, setCohortNameError] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);

  const [clientList, setClientList] = useState([]);
  const [open, setOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');

  const [orConditions, setOrConditions] = useState([
    { id: 1, field: '', operator: '', value: '' }
  ]);
  const [andConditions, setAndConditions] = useState([
    { id: 1, field: '', operator: '', value: '' }
  ]);

  console.log(setClientList);

  const debouncedIsExist = useCallback(
    debounce(
      (value) =>
        checkCohortNameExist({
          cohortName: value,
          setDisableSubmit,
          setCohortNameError
        }),
      1000
    ),
    []
  );

  const handleInputChange = (e) => {
    const { name } = e.target;
    if (name === 'cohortName') {
      setCohortName(e.target.value);
      setIsFieldUpdated(true);
      if (e.target.value) {
        setDisableSubmit(true);
        debouncedIsExist(e.target.value);
      } else {
        setCohortNameError('');
      }
    }
  };

  // handle dialog box states
  const handleCloseConfirmation = (val) => {
    if (val === 'Save') {
      setOpen(false);
    }

    if (val === 'Discard') {
      setOpen(false);
      getCohorts();
      // Here redirecting to the client details page.
      setShowScreen(0);
    }
  };

  // handle close pop-up
  const closePopup = () => {
    setShowSuccessMessage(false);
    setShowScreen(0);
    getCohorts();
  };

  const handleCreateCohortClose = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
    }
  };

  const checkIsCohortNameExist = async ({ cohortName }) => {
    const data = {
      Name: cohortName || ''
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/isExistingCohort`,
        data
      );

      if (response.statusCode && !response.error) {
        return response?.data?.isExists || false;
      }

      return false;
    } catch (error) {
      console.error('Error checking cohort name:', error);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!cohortName || cohortName.trim() === '') {
      setCohortNameError('Cohort Name is required.');
      return;
    }

    const isExists = await checkIsCohortNameExist({ cohortName });

    if (isExists) {
      setCohortNameError(
        'Cohort name already exists. Please choose a unique name.'
      );
      return;
    }

    // Clear error if cohort name doesn't exist
    setCohortNameError('');

    // Proceed with validation if there's no error
    validateConditions({
      orConditions,
      andConditions,
      attributes,
      cohortName,
      getFranchisee,
      setAnyError,
      setAllError,
      setClientList,
      setIsLoading,
      setCohortNameError,
      setShowSuccessMessage,
      setSuccessMessage,
      setShowMessage,
      setErrorMessage,
      type: 'createCohort'
    });
  };

  const [attributes, setAttributes] = useState([]);
  const [anyError, setAnyError] = useState('');
  const [allError, setAllError] = useState('');

  useEffect(() => {
    getAllAttribute({ setAttributes });
  }, []);

  useEffect(() => {
    if (isDuplicate && selectedRow?.Name) {
      setCohortName(selectedRow?.Name);

      const orConditionData = selectedRow?.Segments?.find(
        (item) => item.condition === 'or'
      );

      const andConditionData = selectedRow?.Segments?.find(
        (item) => item.condition === 'and'
      );
      setOrConditions(setConditionData(orConditionData?.rules));
      setAndConditions(setConditionData(andConditionData?.rules));
    }
  }, [selectedRow, isDuplicate]);

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
            mt={1}
          >
            <Box
              onClick={() => handleCreateCohortClose()}
              className="back-arrow-close"
            >
              <ArrowLeft />
            </Box>

            <Typography className="header-blue-32">Create Cohorts</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className="" mt={2} ml={1}>
        <Grid container xs={12} sm={12} mt={2}>
          <Grid item xs={6} sm={6} mt={2}>
            <TextField
              fullWidth
              name="cohortName"
              error={Boolean(cohortNameError)}
              helperText={cohortNameError}
              autoComplete="SENDER NAME"
              label="COHORT NAME *"
              placeholder="Cohort Name"
              className="location-field input-field-styling"
              value={cohortName}
              onChange={handleInputChange}
              InputLabelProps={{
                className: 'label-style-create-franchisee edit-address-label'
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px',
          mt: 5
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          ml={2}
        >
          <Grid item>
            <Typography className="table-heade header-blue-20">
              {' '}
              Criteria
            </Typography>
          </Grid>
        </Grid>
        {/* Any of the following conditions fields */}
        <ConditionBuilder
          conditionName="or"
          conditionValues={orConditions}
          setConditionValues={setOrConditions}
          attributes={attributes}
          setAnyError={setAnyError}
          setAllError={setAllError}
        />
        <Box px={2}>
          <FormHelperText>{anyError && anyError}</FormHelperText>
        </Box>
      </Card>

      <Typography
        textAlign="center"
        className="table-heade header-blue-22"
        my={4}
      >
        {'- AND -'}
      </Typography>

      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px',
          mt: 5
        }}
      >
        {/* All of the following conditions */}
        <ConditionBuilder
          conditionName="and"
          conditionValues={andConditions}
          setConditionValues={setAndConditions}
          attributes={attributes}
          setAnyError={setAnyError}
          setAllError={setAllError}
        />
        <Box px={2}>
          <FormHelperText>{allError && allError}</FormHelperText>
        </Box>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="right"
        gap={2}
        mt={5}
      >
        <Grid item mb={3}>
          <Button
            onClick={() =>
              validateConditions({
                orConditions,
                andConditions,
                attributes,
                cohortName,
                setAnyError,
                setAllError,
                setClientList,
                setIsLoading,
                type: 'getClients'
              })
            }
            className="cohorts-outlined-btn"
            variant="contained"
          >
            View Eligible Clients
          </Button>
        </Grid>
        <Grid item mb={3}>
          <Button
            disabled={disableSubmit || cohortNameError}
            onClick={() => handleSubmit()}
            className="cohorts-outlined-btn"
            variant="contained"
          >
            Create Cohort
          </Button>
        </Grid>
      </Grid>
      <CohortClientTable tableData={clientList} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleCloseConfirmation('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirmation('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseConfirmation('Save')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CreateCohorts;
