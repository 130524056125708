import { ApiService } from 'src/services/ApiService';
import useCreateCohorts from '../../FranchiseeCohorts/Functions/CreateCohortsFunctions';

export default function useCreateFranchisorCohorts() {
  const { createPayload } = useCreateCohorts();

  const validateConditions = ({
    orConditions,
    andConditions,
    attributes,
    cohortName,
    setIsLoading,
    setAllError,
    setAnyError,
    setShowSuccessMessage,
    setSuccessMessage,
    setShowMessage,
    setErrorMessage
  }) => {
    const checkConditions = (conditions, setError) => {
      return conditions?.some((condition) => {
        if (
          conditions?.length > 0 &&
          (!condition.field || !condition.operator || !condition.value)
        ) {
          setError('Missing or empty field, operator, or value.');
          return true;
        }
        return false;
      });
    };

    if (!orConditions?.length > 0 && !andConditions?.length > 0) return null;
    const hasOrError = checkConditions(orConditions, setAnyError);
    const hasAndError = checkConditions(andConditions, setAllError);
    if (!hasOrError && !hasAndError) {
      const segmentPayload = createPayload({
        orConditions,
        andConditions,
        attributes
      });

      CreateCohorts({
        cohortName,
        segmentPayload,
        setShowSuccessMessage,
        setSuccessMessage,
        setShowMessage,
        setErrorMessage,
        setIsLoading
      });
    }
    return hasOrError || hasAndError;
  };

  const CreateCohorts = async ({
    cohortName,
    segmentPayload,
    setShowSuccessMessage,
    setSuccessMessage,
    setShowMessage,
    setErrorMessage,
    setIsLoading
  }) => {
    try {
      setIsLoading(true);
      let data = {
        Name: cohortName,
        createdBy: 'Franchisor',
        Segments: segmentPayload
      };

      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/createCohorts`,
        data
      );

      if (response.statusCode === 200) {
        if (response.error === false) {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setSuccessMessage('Cohort created successfully');
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
      console.error('Error:', error);
    }
  };

  return { validateConditions };
}
