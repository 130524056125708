import { ApiService } from 'src/services/ApiService';

export default function useEditCohorts() {

  const setConditionData = (item) => {
    const data = item?.map((nestedItem, nestedIndex) => {
      return {
        id: nestedIndex + 1,
        field: nestedItem.field,
        operator: nestedItem.operator,
        value: nestedItem.value
      };
    });
    return data;
  };

  const getCohortDetail = async ({
    cohortId,
    partitionKey,
    setCohortData,
    setIsLoading,
    setAndConditions,
    setOrConditions
  }) => {
    let encodedLocationId = encodeURIComponent(partitionKey);
    let encodedCohortId = encodeURIComponent(cohortId);
    setIsLoading(true);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getCohortsDetails?partitionKey=${encodedLocationId}&sortKey=${encodedCohortId}`
      );

      if (!response.error) {
        let data = response?.data?.Items[0];
        setCohortData(response?.data?.Items[0]);
        const orConditionData = data?.Segments?.find((item) => item.condition === 'or');

        const andConditionData = data?.Segments?.find((item) => item.condition === 'and');
        setOrConditions(setConditionData(orConditionData?.rules));
        setAndConditions(setConditionData(andConditionData?.rules));
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);

    }
  };
  return {
    getCohortDetail,
    setConditionData
  };
}
