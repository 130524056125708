import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Grid,
  CardHeader
} from '@mui/material';

export const CohortClientTable = ({ tableData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box>
      <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '12px'
          }}
        >
          <CardHeader
            title={`All Clients ${tableData?.length > 0 ? ` - ${tableData?.length}` : ''}`}
            className="table-header-name"
            sx={{ fontSize: '20px', color: '#003087' }}
          />
        </Grid>
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email ID</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Dog Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.length > 0 ? (
                tableData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data) => {
                    return (
                      <TableRow
                        hover
                        sx={{ cursor: 'default' }}
                        key={data.email}
                        role="checkbox"
                        tabIndex={-1}
                        className="table-row cursor-pointer"
                      >
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            sx={{ marginTop: 1, textTransform: 'capitalize' }}
                            gutterBottom
                            noWrap
                          >
                            {data?.firstName}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {data?.lastName}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {data?.email || ''}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {data?.storePhone || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell className="table-td">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {data?.dogName || '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  className="table-row cursor-pointer"
                >
                  <h4> No Data Found</h4>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={tableData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </Box>
  );
};
