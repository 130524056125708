import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Avatar,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import TrophyEarned from './TrophyEarned';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from 'src/utils';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// PetDetails page style wrapper
const PetDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

// PetDetails page main method
const PetDetails = ({
  petId,
  setShowScreen,
  editPetId,
  selectedClientId,
  petStatus
}) => {
  const navigate = useNavigate();
  const [childId, setPetId] = useState('');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let userId = getLocalData('userId');
  const [open, setOpen] = React.useState(false);
  // pet dialog
  const [openPet, setOpenPet] = React.useState(false);
  const [PetRemoved, setPetRemoved] = useState(false);
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // set the pet details
  const [petDetails, setPetDetails] = useState({});
  // const [completedDoses, setCompletedDoses] = useState([]);
  useEffect(() => {
    // Scrolling window on top at initial
    window.scroll(0, 0);
    // call the get client details method
    if (petId) getChildDetails(petId);
  }, [petId]);

  // useeffect state starts here
  useEffect(() => {
    // check if the user is logged in or not
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [navigate]);

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      // call the api to delete the location
      //  deleteClient();
    }
    setOpen(false);
  };

  const dateFormate = (date) => {
    if (!date) return '';

    // Convert the date format from MM-DD-YYYY to YYYY-MM-DD
    let formattedDate = date?.replace(/-/g, '/');

    // Parse the formatted date with Moment.js
    const utc = moment.utc(formattedDate).format('MMM DD, YYYY');
    return utc;
  };

  // Method to get the client details

  const getChildDetails = async (petId) => {
    let encodedChildId = encodeURIComponent(petId);
    let encodedCLientId = encodeURIComponent(selectedClientId);

    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}client/getChildDetails?childId=${encodedChildId}&clientId=${encodedCLientId}&status=${petStatus}`
      );
      // call the api to get the location details
      setIsLoading(true);

      let data = response.data;
      if (data) {
        setIsLoading(false);
        // set the location details
        let items = data.Items;
        if (items) setPetDetails(items[0]);

        // Now get the completed doses and make is comma separated
        // let obj = (items && items[0] && items[0].completed) || {};
        // let result = Object.keys(obj).filter(key => obj[key] === 'Yes');
        // if (result.length > 0) {
        //   result = result.toString().replace(/,/g, ', ');
        //   setCompletedDoses(result);
        // }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      // set error message
      setShowError(true);
      setErrorMessage(error.message);
    }
  };

  // Method to edit the pet
  const editPet = (petId, e) => {
    e.stopPropagation();
    e.preventDefault();
    // set the pet id
    setPetId(petId);
    editPetId(petId);
  };

  // Method to delete the pet
  const deletePet = (petId, e) => {
    e.stopPropagation();
    e.preventDefault();

    // set the pet id
    setPetId(petId);
    // open confirmation dialog
    setOpenPet(true);
  };

  // Method to close the delete dialog
  const handlePetClose = (val) => {
    if (val === 'Delete') {
      // call the api to delete the location
      confirmDeletePet();
    }
    setOpenPet(false);
  };

  // Method to delete the pet
  const confirmDeletePet = async () => {
    if (childId) {
      let body = {
        id: childId,
        clientId: selectedClientId,
        userId: userId
      };

      try {
        // Now show the loader
        setIsLoading(true);
        // call the api to delete the location
        const response = await ApiService.delete(
          `${process.env.REACT_APP_API_END_POINT}client/deleteChild`,
          body
        );
        let data = response.data;

        if (data) {
          setPetId('');

          // Now hide the loader
          setIsLoading(false);
          setShowScreen(3);
          setPetRemoved(true);
        }
      } catch (error) {
        console.error('Error:', error);
        // set error message
        setShowError(true);
        setErrorMessage(error.message);
        // Now hide the loader
        setIsLoading(false);
      }
    }
  };

  const handleCloseDetails = () => {
    setShowScreen(3);
  };

  return (
    <>
      {petStatus === 0 && <div className="full-page-grey-overlay" />}
      <Loader IsLoading={isLoading} />
      <PetDetailsWrapper maxWidth={'100%'} className="location-details-wrapper">
        {petDetails && (
          <>
            <Grid
              className="location-header-container"
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ paddingBottom: '15px' }}
              disabled
            >
              <Grid item className="non-grey-overlay">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    onClick={() => handleCloseDetails()}
                    className="back-arrow-close"
                  >
                    <ArrowLeft />
                  </Box>
                  <Typography className="header-blue-32">
                    {petDetails?.firstName} {petDetails?.lastName}
                  </Typography>
                </Box>
              </Grid>
              {/* Delete icon and edit button */}
              <Grid item>
                {/* remove icon */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="outlined"
                    className="details-remove-icon no-hover"
                    onClick={(e) => deletePet(petDetails?.sortKey, e)}
                    sx={{ border: '2px solid #003087 !important' }}
                    disabled={petStatus === 0}
                  >
                    Remove Dog
                  </Button>
                  {/* Edit Button */}
                  <Button
                    sx={{
                      mt: { xs: 0, md: 0 },
                      backgroundColor:
                        petStatus === 0 ? 'gray' : 'primary.main',
                      color: petStatus === 0 ? 'white' : 'primary.contrastText',
                      '&:disabled': {
                        backgroundColor: 'lightgray',
                        color: 'darkgray',
                        ml: '10px'
                      }
                    }}
                    variant="contained"
                    id={petDetails?.sortKey}
                    disabled={petStatus === 0}
                    onClick={(e) => editPet(petDetails?.sortKey, e)}
                    className="btn-primary edit-button common-button"
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box className="location-details-container">
              {/* <Grid container spacing={2} > */}
              <Grid
                container
                spacing={2}
                mt={2}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">NAME</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.firstName} {petDetails?.lastName}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">GENDER</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.gender}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                mt={2}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">
                    Acquisition Source
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.acquisitionSource}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">BREED</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.breed}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                mt={2}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label text-blac">STATUS</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.status === 1 ? 'Active' : 'Deactive'}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">Veterinarian</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.veterinarian}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                mt={2}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">DATE OF BIRTH</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  {/* <Box className="location-details-value">Your dog is {petAge} old</Box> */}
                  <Box className="location-details-value">
                    {dateFormate(petDetails?.birthDate)}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">BARKING</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.barking}
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                mt={2}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">STRANGERS</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.strangers}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">BITING</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.biting}
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                mt={2}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">POSSESSIVE</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.possessive}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">ADVANCED</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.advanced}
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                mt={1}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">SOCIAL</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {petDetails?.social}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2.5}
                  className="location-details-col d-flex align-items-center"
                >
                  <Box className="location-details-label">PROFILE IMAGE</Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3.5}
                  className="location-details-col pl-0"
                >
                  <Box className="location-details-value">
                    <Box className="location-details-value location-image">
                      <Avatar
                        alt="icon"
                        src={
                          petDetails?.profileImage ||
                          '/static/images/logo/cardboardicon.png'
                        }
                        sx={{ marginLeft: 2 }}
                        className="card-avatar-img"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* Vaccination Details Details */}
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="page-header title-adjust mt-10"
              >
                <span className="location-name text-wrap font-20 mt-10">
                  Vaccination Details
                </span>
              </Grid>
              <Grid
                container
                spacing={2}
                mt={2}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label ">RABIES EXP DATE</Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {dateFormate(petDetails?.rabiesExpiration)}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label ">
                    BORDETELLA EXP DATE
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {dateFormate(petDetails?.bordetellaExpiration)}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                mt={2}
                className="location-details-row"
              >
                <Grid item xs={12} sm={2.5} className="location-details-col">
                  <Box className="location-details-label">
                    COMBO SHOT EXPIRATION
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3.5} className="location-details-col">
                  <Box className="location-details-value">
                    {dateFormate(petDetails?.dhppExpiration)}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} className="location-details-col">
                  <Box className="location-details-label">
                    ATTACHED DOCUMENT
                  </Box>
                  <Box className="location-details-value">
                    {petDetails?.documentList?.length > 0 ? (
                      petDetails?.documentList?.map((item, index) => {
                        // let arr = item?.documentUrl?.split('-');
                        // let name = arr[arr.length - 1];
                        // name = name && decodeURIComponent(name);
                        // render the document list with comma separated
                        return (
                          <span key={index}>
                            <a
                              href={item.documentUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="text-grey"
                            >
                              {/* {item.documentName} */}
                              {/* {item.documentUrl.split('-')[5] || 'View Document'} */}
                              {item.documentName || 'View Document'}
                            </a>
                            {index !== petDetails.documentList.length - 1
                              ? ', '
                              : ''}
                          </span>
                        );
                      })
                    ) : (
                      <span>No document attached</span>
                    )}
                  </Box>
                </Grid>
              </Grid>

              {/* Trophy Earned */}
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="page-header title-adjust mt-40"
              >
                <span className="location-name text-wrap font-20 mt-10">
                  Trophy Earned
                </span>
              </Grid>
              <Grid container mt={2} className="location-details-row">
                <TrophyEarned
                  clientId={selectedClientId}
                  petId={petId}
                  petStatus={petStatus}
                />
              </Grid>

              {/* </Grid> */}
              {showError && (
                <Grid container mt={3} className="location-details-row">
                  <Box className="error-message">{errorMessage}</Box>
                </Grid>
              )}
            </Box>
          </>
        )}
      </PetDetailsWrapper>
      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete pet confirmation dialog */}
      <Dialog
        open={openPet}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handlePetClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Remove'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to remove this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handlePetClose('Delete')}
          >
            Remove
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handlePetClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* {dog removed successfully popup} */}
      <Dialog
        open={PetRemoved}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPetRemoved(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Selected dog has been removed successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => setPetRemoved(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PetDetails;