import React from 'react';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  Paper,
  Box,
  Grid,
  IconButton,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CohortDatePicker } from './Functions/CohortDatePicker';

const ConditionBuilder = ({
  conditionName,
  conditionValues,
  setConditionValues,
  attributes,
  disableFields,
  setAnyError,
  setAllError
}) => {
  const addCondition = () => {
    setAnyError('');
    setAllError('');
    setConditionValues((prev) => [
      ...prev,
      { id: prev.length + 1, field: '', operator: '', value: '' }
    ]);
  };

  const removeCondition = (id) => {
    setAnyError('');
    setAllError('');
    setConditionValues((prev) =>
      prev.filter((condition) => condition.id !== id)
    );
  };

  const handleFieldChange = (id, value) => {
    setConditionValues((prev) =>
      prev?.map((condition) =>
        condition.id === id
          ? { ...condition, field: value, operator: '', value: '' }
          : condition
      )
    );
  };

  const handleOperatorChange = (id, value) => {
    setConditionValues((prev) =>
      prev?.map((condition) =>
        condition.id === id
          ? { ...condition, operator: value, value: '' }
          : condition
      )
    );
  };

  const handleValueChange = (id, value) => {
    setAnyError('');
    setAllError('');
    setConditionValues((prev) =>
      prev?.map((condition) =>
        condition.id === id ? { ...condition, value } : condition
      )
    );
  };

  const changeStartDate = (id, value) => {
    console.log(value, ' date is consoled here');
    handleValueChange(id, value);
  };

  function convertToReadableFormat(input) {
    return input
      .replace(/([A-Z])/g, ' $1') // Add a space before each capital letter
      .trim() // Remove any leading or trailing spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  }

  const renderValueInput = (condition) => {
    const valueType =
      attributes?.find((field) => field?.field === condition?.field) || '';

    // Check if the field is 'createdAt'
    if (condition.field === 'createdAt') {
      switch (condition.operator) {
        case 'equal':
          return (
            <TextField
              fullWidth
              type="number"
              value={condition.value}
              onChange={(e) => handleValueChange(condition.id, e.target.value)}
              disabled={!condition.operator || disableFields}
            />
          );

        case 'lessThan':
        case 'greaterThan':
          return (
            <CohortDatePicker
              label="Date"
              disabled={!condition.operator || disableFields}
              fieldValue={condition?.value || ''}
              onChange={(value) => changeStartDate(condition?.id, value)}
            />
          );

        default:
          return (
            <TextField
              fullWidth
              type="number"
              value={condition.value}
              onChange={(e) => handleValueChange(condition.id, e.target.value)}
              disabled={!condition.operator || disableFields}
            />
          );
      }
    } else {
      // Handle other field types
      switch (valueType?.type) {
        case 'number':
          return (
            <TextField
              fullWidth
              type="number"
              value={condition.value}
              onChange={(e) => handleValueChange(condition.id, e.target.value)}
              disabled={!condition.operator || disableFields}
            />
          );

        case 'string':
          return (
            <Select
              fullWidth={false} // Disable full width to apply a custom width
              multiple
              value={Array.isArray(condition?.value) ? condition?.value : []}
              onChange={(e) => handleValueChange(condition.id, e.target.value)}
              disabled={!condition.operator || disableFields}
              style={{ width: 270 }} // Set the fixed width here, adjust the value as needed
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200
                  }
                }
              }}
            >
              {valueType?.dataValue?.sort().map((service) => (
                <MenuItem key={service} value={service}>
                  {condition?.field === 'status'
                    ? convertToReadableFormat(service)
                    : service}
                </MenuItem>
              ))}
            </Select>
          );

        case 'boolean':
          return (
            <FormControl component="fieldset" disabled={!condition.operator}>
              <RadioGroup
                row
                value={condition.value}
                onChange={(e) =>
                  handleValueChange(condition.id, e.target.value)
                }
              >
                {valueType?.values?.map((value) => (
                  <FormControlLabel
                    key={value}
                    disabled={disableFields}
                    value={value}
                    control={<Radio />}
                    label={value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          );

        default:
          return (
            <TextField
              fullWidth
              type="text"
              value={condition.value}
              onChange={(e) => handleValueChange(condition.id, e.target.value)}
              disabled={!condition.operator || disableFields}
            />
          );
      }
    }
  };

  return (
    <Paper style={{ padding: '20px', margin: 'auto' }}>
      <Box display="flex" flexDirection="column" mb={2}>
        <Grid container display="flex" justifyContent="space-between" mb={3}>
          <Typography sx={{ fontSize: '22px', color: '#003087' }}>
            {conditionName === 'or' ? (
              <>
                Meet <strong>ANY</strong> of the following conditions:
              </>
            ) : (
              <>
                Meet <strong>ALL</strong> of the following conditions:
              </>
            )}
          </Typography>
          {!disableFields && (
            <Button variant="contained" onClick={addCondition}>
              ADD CONDITION
            </Button>
          )}
        </Grid>
        {conditionValues?.map((condition) => {
          const fieldData = attributes?.find(
            (field) => field.field === condition.field
          );
          const operators = fieldData ? fieldData.operators : [];

          return (
            <Grid
              container
              key={condition.id}
              spacing={2}
              mb={2}
              alignItems="center"
            >
              <Grid item xs>
                <Select
                  fullWidth
                  disabled={disableFields}
                  value={condition.field}
                  onChange={(e) =>
                    handleFieldChange(condition.id, e.target.value)
                  }
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200
                      }
                    }
                  }}
                >
                  {attributes?.map((field) => (
                    <MenuItem key={field.field} value={field.field}>
                      {field.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs>
                <Select
                  fullWidth
                  value={condition.operator}
                  onChange={(e) =>
                    handleOperatorChange(condition.id, e.target.value)
                  }
                  displayEmpty
                  disabled={!condition.field || disableFields}
                >
                  {operators?.map((operator) => (
                    <MenuItem key={operator.value} value={operator.value}>
                      {operator.key}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs>
                {renderValueInput(condition)}
              </Grid>
              {!disableFields && (
                <Grid item>
                  <IconButton onClick={() => removeCondition(condition.id)}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Box>
    </Paper>
  );
};

export default ConditionBuilder;
