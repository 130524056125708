import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  DialogActions,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import { getLocalData } from 'src/utils';
import React, { useState } from 'react';
import Loader from '../../../components/Loader';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { formatPhoneNumber } from 'src/utils';

import { InfoIcon } from 'src/assets/SVG/SvgIcons';
import { Toggle } from 'src/components/Global';
import { ApiService } from 'src/services/ApiService';

const AddLocation = ({
  LocationAdded,
  setShowScreen,
  Transition,
  franchiseeList,
  managerList,
  statesList
}) => {
  console.log(LocationAdded);
  // create a custom theme with updated hover and close icon colors

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        //  color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087',
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();

  // REGX FOR VALID 5 DIGIT ZIP CODE
  const zipCodePattern = /^0\d{4}$|^[1-9]\d{4}$/;

  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [locationName, setLocationName] = useState('');
  const [instagramHandle, setInstagramHandle] = useState('');

  const [primaryContactName, setPrimaryContactName] = useState('');

  const [unitName, setUnitName] = useState('');
  const [email, setEmail] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState('');
  const [liveLocation, setLiveLocation] = useState(false);

  const [workPhoneNumber, setWorkPhoneNumber] = useState('');
  const [statusvalue, setStatus] = useState('');
  const [franchiseeId, setFranchiseeId] = useState('');
  const [franchiseeName, setFranchiseeName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setpostalCode] = useState('');
  const [locationUrl, setLocationUrl] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');

  const [openingDate, setOpeningDate] = useState('');
  const [locationDescription, setLocationDescription] = useState('');
  const [assignManager, setAssignManager] = useState([]);
  const [taxGrouping, setTaxGrouping] = useState(false);
  const [taxExempt, setTaxExempt] = useState(false);
  const [isCreated, setisCreated] = useState(false);

  const [locationNameHelperText, setLocationNameHelperText] = useState('');
  const [instagramHandleHelperText, setinstagramHandleHelperText] =
    useState('');

  const [primaryContactNameHelperText, setPrimaryContactNameHelperText] =
    useState('');

  const [unitHelperText, setUnitHelperText] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [primaryEmailHelperText, setPrimaryEmailHelperText] = useState('');

  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState(false);
  const [primaryPhoneNumberHelperText, setprimaryPhoneNumberHelperText] =
    useState(false);

  const [franchiseeHelperText, setFranchiseeHelperText] = useState(false);
  const [addressLine1HelperText, setAddressLine1HelperText] = useState(false);
  const [stateHelperText, setStateHelperText] = useState(false);
  const [cityHelperText, setCityHelperText] = useState(false);
  const [postalCodeHelperText, setpostalCodeHelperText] = useState(false);
  const [twilioNumberHelperText, setTwilioNumberHelperText] = useState(false);
  const [locationUrlHelperText, setLocationUrlHelperText] = useState(false);
  const [facebookUrlHelperText, setFacebookUrlHelperText] = useState(false);

  const [statusError, setStatusError] = useState(false);
  const [statusHelperText, setStatusHelperText] = useState('');
  const [openingDateError, setOpeningDateError] = useState(false);

  const [locationDescriptionError, setLocationDescriptionError] =
    useState(false);
  const [assignManagerError, setAssignManagerError] = useState(false);
  const [twilioNumber, setTwilioNumber] = useState('');

  const [anyFieldUpdated, setanyFieldUpdated] = useState(false);

  const [openDialog, setopenDialog] = useState(false);

  const [assignManagerName, setAssignManagerName] = useState('');
  const [assignManagerId, setAssignManagerId] = useState('');
  console.log(openingDate, statusHelperText, taxGrouping, taxExempt);

  console.log('workPhoneNumber', workPhoneNumber, addressLine2, assignManager);

  const initialValues = {
    locationName: '',
    primaryContactName: '',

    unit: '',
    phoneNumber: '',
    primaryPhoneNumber: '',

    locationDescription: '',
    email: '',
    primaryEmail: '',
    twilioNumber: '',
    openingDate: '',
    assignManager: '',
    statusvalue: '',
    franchiseeName: '',
    franchiseeId: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    postalCode: '',
    imgObj: '',
    locationUrl: '',
    facebookUrl: '',
    instagramHandle: ''
  };

  let userId = getLocalData('userId');
  const [formValues, setFormValues] = useState(initialValues);

  /* Function binded on close button of create-franchisee/user form dialog */
  const handleClose = () => {
    if (anyFieldUpdated) {
      setopenDialog(true);
    } else {
      setShowScreen(0);

      clear();
    }
  };

  /* Function for confirmation modal pop up(close,discard,continue) */

  const handleCloseConfirm = (value) => {
    if (value === 'Discard') {
      setopenDialog(false);
      setShowScreen(0);

      clear();
    } else if (value === 'Close') {
      setopenDialog(false);
      setShowScreen(0);

      clear();
    } else if (value === 'Continue') {
      setopenDialog(false);
    }
  };

  const clear = () => {
    setLocationName('');
    setInstagramHandle('');
    setUnitName('');
    setEmail('');
    setLocationDescription('');
    setWorkPhoneNumber('');
    setOpeningDate('');
    setAssignManager('');
    setAssignManagerId('');
    setAssignManagerName('');
    setStatus('');
    setFranchiseeName('');
    setPhoneNumber('');
    setAddressLine1('');
    setAddressLine2('');
    setpostalCode('');
    setFranchiseeId('');
    setState('');
    setCity('');
    setPrimaryEmail('');
    setPrimaryContactName('');
    setPrimaryPhoneNumber('');
    setLocationUrl('');
    setFacebookUrl('');
    setInstagramHandle('');
    setTwilioNumber('');
    setLiveLocation(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'locationName') {
      setLocationName(e.target.value);

      setLocationNameHelperText('');
      setanyFieldUpdated(true);
    }

    if (name === 'primaryContactName') {
      setPrimaryContactName(e.target.value);

      setPrimaryContactNameHelperText('');
      setanyFieldUpdated(true);
    }

    if (name === 'unit') {
      setUnitName(e.target.value);

      setUnitHelperText('');
      setanyFieldUpdated(true);
    }

    if (name === 'email') {
      setEmail(value);
      if (value === '') {
        setEmailHelperText('');
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        setEmailHelperText('Please enter a valid email address');
      } else {
        setEmail(value);

        setEmailHelperText('');
      }
      setanyFieldUpdated(true);
    }

    if (name === 'primaryEmail') {
      setPrimaryEmail(value);
      if (value === '') {
        setPrimaryEmailHelperText('');
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        setPrimaryEmailHelperText('Please enter a valid primary email address');
      } else {
        setPrimaryEmail(value);

        setPrimaryEmailHelperText('');
      }
      setanyFieldUpdated(true);
    }

    if (name === 'openingDate') {
      setOpeningDate(e.target.value);
      setOpeningDateError(false);
      setanyFieldUpdated(true);
    }

    if (name === 'locationDescription') {
      setLocationDescription(e.target.value);

      setLocationDescriptionError('');
      setanyFieldUpdated(true);
    }

    if (name === 'phoneNumber') {
      const validation = /^\d{3}-\d{3}-\d{4}$/;

      if (e.target.value) {
        let formatWorkPhoneNumber = formatPhoneNumber(e.target.value);
        setPhoneNumber(formatWorkPhoneNumber);
        setanyFieldUpdated(true);

        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setPhoneNumberHelperText(
            'Please enter a valid phone number in format: xxx-xxx-xxxx'
          );
        } else {
          setPhoneNumberHelperText('');
        }
      } else {
        setPhoneNumberHelperText('');
        setPhoneNumber('');
      }
    }

    if (name === 'primaryPhoneNumber') {
      const validation = /^\d{3}-\d{3}-\d{4}$/;

      if (e.target.value) {
        let formatWorkPhoneNumber = formatPhoneNumber(e.target.value);
        setPrimaryPhoneNumber(formatWorkPhoneNumber);
        setanyFieldUpdated(true);

        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setprimaryPhoneNumberHelperText(
            'Please enter a valid primary phone number in format: xxx-xxx-xxxx'
          );
        } else {
          setprimaryPhoneNumberHelperText('');
        }
      } else {
        setprimaryPhoneNumberHelperText('');
        setPrimaryPhoneNumber('');
      }
    }

    if (name === 'twilioNumber') {
      const validation = /^\d{3}-\d{3}-\d{4}$/;

      if (e.target.value) {
        let formatWorkPhoneNumber = formatPhoneNumber(e.target.value);
        setTwilioNumber(formatWorkPhoneNumber);
        setanyFieldUpdated(true);

        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setTwilioNumberHelperText(
            'Please enter a valid twilio number in format: xxx-xxx-xxxx'
          );
        } else {
          setTwilioNumberHelperText('');
        }
      } else {
        setTwilioNumberHelperText('');
        setTwilioNumber('');
      }
    }

    if (name === 'status') {
      setStatus(e.target.value);
      setStatusError(false);
      setStatusHelperText('');
      setanyFieldUpdated(true);
    }

    if (name === 'franchiseeId') {
      setFranchiseeId(e.target.value.franchiseeId);
      setFranchiseeName(e.target.value.franchiseeName);

      setFranchiseeHelperText('');
      setanyFieldUpdated(true);
    }

    if (name === 'addressLine1') {
      setAddressLine1(e.target.value);

      setAddressLine1HelperText('');
      setanyFieldUpdated(true);
    }

    if (name === 'addressLine2') {
      setAddressLine2(e.target.value);

      setanyFieldUpdated(true);
    }

    if (name === 'state') {
      setState(e.target.value);

      setStateHelperText('');
      setanyFieldUpdated(true);
    }

    if (name === 'city') {
      if (!e.target.value) {
        setCity(e.target.value);
        setCityHelperText('');
      } else if (!/^[a-zA-Z ]*$/.test(e.target.value)) {
        setCity(e.target.value);
        setCityHelperText('Enter alphabet characters only');
      } else {
        setCity(e.target.value);

        setCityHelperText('');
      }
      setanyFieldUpdated(true);
    }

    if (name === 'postalCode') {
      if (!e.target.value) {
        setpostalCodeHelperText('');
        setpostalCode('');
      } else if (!/^\d{5}$/.test(e.target.value)) {
        setpostalCodeHelperText('Please enter a valid 5-digit zip code');
        setpostalCode(e.target.value);
      } else {
        setpostalCode(e.target.value);
        setpostalCodeHelperText('');
      }
      setanyFieldUpdated(true);
    }

    if (name === 'locationUrl') {
      setanyFieldUpdated(true);
      setLocationUrlHelperText('');
      setLocationUrl(e.target.value);
    }

    if (name === 'facebookUrl') {
      setanyFieldUpdated(true);
      setFacebookUrlHelperText('');
      setFacebookUrl(e.target.value);
    }

    if (name === 'instagramHandle') {
      setanyFieldUpdated(true);
      setinstagramHandleHelperText('');
      setInstagramHandle(e.target.value);
    }

    if (name === 'taxGrouping') {
      setTaxGrouping(e.target.checked);

      setanyFieldUpdated(true);
    }
    if (name === 'taxExempt') {
      setTaxExempt(e.target.checked);

      setanyFieldUpdated(true);
    }

    if (name === 'franchiseeId') {
      setFormValues({
        ...formValues,
        franchiseeName: value ? value.franchiseeName : '',
        franchiseeId: value ? value.franchiseeId : ''
      });
      setanyFieldUpdated(true);
    } else {
      setFormValues({
        ...formValues,
        [name]: value
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let zipCodeValid = true;

    if (!locationName) {
      setLocationNameHelperText('Location name is required.');
    }

    if (!unitName) {
      setUnitHelperText('Unit is required.');
    }

    if(!email){
      setEmailHelperText('Location Email is required.')
    }

    if (phoneNumber && !/^\d{3}-\d{3}-\d{4}$/.test(phoneNumber)) {
      setPhoneNumberHelperText(
        'Please enter a valid phone number in format: xxx-xxx-xxxx.'
      );
    }

    if (primaryPhoneNumber && !/^\d{3}-\d{3}-\d{4}$/.test(primaryPhoneNumber)) {
      setprimaryPhoneNumberHelperText(
        'Please enter a valid primary phone number in format: xxx-xxx-xxxx.'
      );
    }

    if (!franchiseeId && !franchiseeName) {
      setFranchiseeHelperText('Business entity is required.');
    }

    if (assignManager.length === 0) {
      setAssignManagerError(true);
    }

    if (!statusvalue) {
      setStatusError(true);
    }

    if (!addressLine1) {
      setAddressLine1HelperText('Address Line 1 is required.');
    }

    if (!state) {
      setStateHelperText('State is required.');
    }

    if (!city) {
      setCityHelperText('City is required.');
    } else if (city && !/^[a-zA-Z ]*$/.test(city)) {
      setCityHelperText('Enter alphabet characters only.');
    }

    if (!postalCode) {
      setpostalCodeHelperText('Zip Code is required.');
    } else if (postalCode && !zipCodePattern.test(postalCode)) {
      zipCodeValid = false;
      setpostalCodeHelperText('Please enter a valid zip code.');
    }

    if (
      !twilioNumberHelperText?.length > 0 &&
      !primaryPhoneNumberHelperText?.length > 0 &&
      !phoneNumberHelperText?.length > 0 &&
      !emailHelperText?.length > 0 &&
      !primaryEmailHelperText?.length > 0 &&
      !cityHelperText?.length > 0 &&
      !postalCodeHelperText?.length > 0 &&
      assignManagerName !== '' &&
      assignManagerId !== '' &&
      locationName !== '' &&
      email !== '' &&
      unitName !== '' &&
      zipCodeValid &&
      franchiseeId !== '' &&
      franchiseeName !== '' &&
      franchiseeId !== undefined &&
      franchiseeName !== undefined &&
      statusvalue !== '' &&
      addressLine1 !== '' &&
      city !== '' &&
      state !== ''
    ) {
      addLocation();
    }

    // Do something with form data
  };

  const addLocation = async () => {
    setIsLoading(true);

    let data = {
      franchiseeId: formValues.franchiseeId,
      franchiseeName: franchiseeName,
      locationName: formValues.locationName,
      'unit#': formValues.unit,

      phone: formatPhoneNumber(formValues.phoneNumber)?.toString(),
      email: formValues.email.toLowerCase(),
      notes: formValues.locationDescription,
      openingDate: openingDate,
      managerId: assignManagerId,
      managerName: assignManagerName,
      openingStatus: statusvalue,
      primaryContactName: formValues.primaryContactName,
      primaryContactEmail: formValues.primaryEmail,

      primaryContactPhone: formatPhoneNumber(
        formValues.primaryPhoneNumber
      )?.toString(),
      addressLine1: formValues.addressLine1,
      addressLine2: formValues.addressLine2,
      city: formValues.city,
      state: formValues.state,
      postalCode: formValues.postalCode?.toString(),
      locationUrl: formValues.locationUrl?.toString(),
      facebookUrl: formValues.facebookUrl?.toString(),
      twilioNumber: twilioNumber,
      instagramUrl: formValues.instagramHandle,

      openingFlag: liveLocation,

      createdBy: userId,
      status: 1
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}locations/createLocation`,
        data
      );

      if (!response?.error) {
        setIsLoading(false);

        let crossElm = document.getElementsByClassName('user-close')[0];
        console.log(crossElm);

        if (crossElm) crossElm?.click();

        setisCreated(true);
        setopenDialog(true);
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setErrorMessage(
          response.message || 'Something went wrong with create location'
        );
      }
    } catch (error) {
      setIsLoading(false);
      let msg = error?.message;
      setShowMessage(true);
      setErrorMessage(msg || 'Internal Server Error');
    }
  };

  /* Function for assign manager drop down to be filled */
  const assignManagerValue = (assignManager) => {
    if (!assignManager) {
      setAssignManager('');
      setAssignManagerError(true);
      setAssignManagerName('');
      setAssignManagerId('');
      setanyFieldUpdated(false);
    } else {
      setAssignManagerError(false);
      setAssignManager(assignManager);
      setAssignManagerName(assignManager.split('|')[1]);
      setAssignManagerId(assignManager.split('|')[0]);
      setanyFieldUpdated(true);
    }
  };

  const validateOpeningDate = (openingDate) => {
    setanyFieldUpdated(true);
    setOpeningDate(openingDate);
    setOpeningDateError('');
  };

  const handleToggleLiveLocation = (type, value) => {
    setanyFieldUpdated(true);
    setLiveLocation(value);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      <Grid sx={{ padding: '0 2rem' }}>
        <Box className="common-form-container">
          <Box>
            <Box
              className="container-text-field"
              component="form"
              onSubmit={handleSubmit}
            >
              {/* <Card
              
            > */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ paddingBottom: '15px' }}
              >
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box
                      onClick={() => handleClose()}
                      className="back-arrow-close"
                    >
                      <svg
                        width="27"
                        height="19"
                        viewBox="0 0 27 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                          fill="#003087"
                        />
                      </svg>
                    </Box>
                    <Typography className="header-blue-32">
                      Add New Location
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      className="common-button"
                    >
                      <Typography>Save</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Card
                className="create-franchise-form"
                sx={{ border: '0.4px solid #C5C5C5', padding: '0 20px' }}
              >
                <CardContent id="location-form">
                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="locationName"
                        name="locationName"
                        label="LOCATION NAME"
                        type="text"
                        className="input-field-styling required-field "
                        value={locationName}
                        onChange={handleInputChange}
                        // error={firstNameError}
                        helperText={locationNameHelperText}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="unit"
                        name="unit"
                        label="UNIT #"
                        type="text"
                        className="input-field-styling required-field"
                        value={unitName}
                        onChange={handleInputChange}
                        // error={lastNameError}
                        helperText={unitHelperText}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label="LOCATION PHONE NUMBER"
                        type="text"
                        placeholder="777-777-7777"
                        className="input-field-styling "
                        onChange={handleInputChange}
                        value={phoneNumber}
                        autoComplete="off"
                        // error={phoneNumberError}
                        helperText={phoneNumberHelperText}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        inputProps={{ maxLength: 12 }}
                      />
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="LOCATION EMAIL"
                        placeholder="Email"
                        className="input-field-styling required-field"
                        // error={emailError}
                        helperText={emailHelperText}
                        value={email}
                        sx={{ mt: 2 }}
                        onChange={handleInputChange}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        multiline
                        id="locationDescription"
                        name="locationDescription"
                        autocomplete="LOCATION Description"
                        label="LOCATION NOTES"
                        placeholder="Notes here . . . "
                        variant="outlined"
                        value={locationDescription}
                        className="location-description"
                        error={Boolean(locationDescriptionError)}
                        helperText={locationDescriptionError}
                        onChange={handleInputChange}
                        InputLabelProps={{
                          className: 'label-style-create-franchisee'
                        }}
                        InputProps={{ rows: 6 }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6} className="form-datepicker-container">
                      <CustomDatePicker
                        label="OPENING DATE"
                        onChange={validateOpeningDate}
                      />
                      {openingDateError && (
                        <FormHelperText>
                          Opening Date is required
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          ASSIGN SUPPORT SPECIALIST
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="ASSIGN SUPPORT SPECIALIST"
                          className="input-field-styling"
                          value={assignManager}
                          onChange={(event) =>
                            assignManagerValue(event.target.value)
                          }
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } }
                          }}
                          // renderValue={(selected) => selected.join(', ')}
                        >
                          {managerList.length > 0 &&
                            managerList.map((item) => (
                              <MenuItem
                                key={item.userId}
                                id={item.userId}
                                // value={item.userId}
                                value={
                                  item.userId +
                                  '|' +
                                  item.firstName +
                                  ' ' +
                                  item.lastName
                                }
                              >
                                {item.firstName + ' ' + item.lastName}
                              </MenuItem>
                            ))}
                        </Select>
                        {assignManagerError && (
                          <FormHelperText>
                            Please select assign support specialist.
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6}>
                      <Autocomplete
                        classes={autoCompleteClasses}
                        onChange={(event, newValue) => {
                          if (newValue && newValue) {
                            handleInputChange({
                              target: { name: 'franchiseeId', value: newValue }
                            });
                          } else {
                            handleInputChange({
                              target: { name: 'franchiseeId', value: '' }
                            });
                          }
                        }}
                        className="dropdown-field field-label-css status-field-lbl"
                        options={franchiseeList}
                        getOptionLabel={(option) => option.franchiseeName}
                        sx={{
                          width: '100%',
                          borderRadius: '19px ! important'
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={params?.label || 'BUSINESS ENTITY'}
                            className="required-field"
                            InputLabelProps={{
                              className: 'required-label'
                            }}
                            helperText={franchiseeHelperText}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          STATUS
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="status"
                          label="STATUS"
                          className="input-field-styling"
                          onChange={handleInputChange}
                        >
                          <MenuItem value={'Location Search'}>
                            Location Search
                          </MenuItem>
                          <MenuItem value={'Location Negotiation'}>
                            Location Negotiation
                          </MenuItem>
                          <MenuItem value={'LOI Signed'}>LOI Signed</MenuItem>
                          <MenuItem value={'Lease Signed'}>
                            Lease Signed
                          </MenuItem>
                          <MenuItem value={'Under Construction'}>
                            Under Construction
                          </MenuItem>
                          <MenuItem value={'Open'}> Open</MenuItem>
                        </Select>
                        {statusError && (
                          <FormHelperText>Please select status.</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="primaryContactName"
                        name="primaryContactName"
                        label="PRIMARY CONTACT NAME"
                        placeholder="Contact Name"
                        type="text"
                        className="input-field-styling "
                        value={primaryContactName}
                        onChange={handleInputChange}
                        // error={firstNameError}
                        helperText={primaryContactNameHelperText}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="primaryEmail"
                        name="primaryEmail"
                        label="PRIMARY CONTACT EMAIL"
                        placeholder="Email"
                        className="input-field-styling"
                        // error={emailError}
                        helperText={primaryEmailHelperText}
                        value={primaryEmail}
                        onChange={handleInputChange}
                        InputLabelProps={{
                          className: 'form-field-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="primaryPhoneNumber"
                        name="primaryPhoneNumber"
                        label="PRIMARY CONTACT PHONE "
                        type="text"
                        autoComplete="off"
                        placeholder="777-777-7777"
                        className="input-field-styling "
                        onChange={handleInputChange}
                        value={primaryPhoneNumber}
                        // error={phoneNumberError}
                        helperText={primaryPhoneNumberHelperText}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        inputProps={{ maxLength: 12 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Typography className="text-fiel check-box-text">
                        Live Location
                      </Typography>
                      <Toggle
                        value={liveLocation}
                        name="liveLocation"
                        onChange={handleToggleLiveLocation}
                      />

                      {/* left arrow icon */}
                      <Tooltip
                        arrow
                        title={
                          'Enabled this to make the location available, or disabled this to make the location unavailable.'
                        }
                        placement="bottom"
                      >
                        <IconButton className="no-hover">
                          <InfoIcon className="info-icon" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="twilioNumber"
                        name="twilioNumber"
                        label="TWILIO NUMBER"
                        type="text"
                        className="input-field-styling"
                        onChange={handleInputChange}
                        value={twilioNumber}
                        autoComplete="off"
                        helperText={twilioNumberHelperText}
                        InputLabelProps={{
                          className: 'label-style-create-franchisee'
                        }}
                        inputProps={{ maxLength: 12 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="field-top-margin">
                    <Grid item xs={12} sm={6}>
                      <Typography className="table-heade header-blue-20">
                        Location Address
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="addressLine1"
                        name="addressLine1"
                        label="ADDRESS LINE 1"
                        type="text"
                        className="input-field-styling required-field"
                        onChange={handleInputChange}
                        value={addressLine1}
                        // error={addressLine1Error}
                        helperText={addressLine1HelperText}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="addressLine2"
                        name="addressLine2"
                        label="ADDRESS LINE 2"
                        type="text"
                        className="input-field-styling"
                        onChange={handleInputChange}
                        value={addressLine2}
                        InputLabelProps={{
                          className: 'form-field-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6}>
                      <FormControl
                        //   error={stateHelperText}
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          STATE
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="state"
                          label="STATE"
                          value={formValues.state}
                          className="input-field-styling"
                          onChange={handleInputChange}
                          // error={Boolean(stateError)}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } }
                          }}
                        >
                          {statesList.map((state) => (
                            <MenuItem key={state.state} value={state.state}>
                              {state.state}
                            </MenuItem>
                          ))}
                        </Select>
                        {stateHelperText && (
                          <FormHelperText error>
                            {stateHelperText}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="city"
                        name="city"
                        label="CITY"
                        type="text"
                        className="input-field-styling required-field"
                        onChange={handleInputChange}
                        value={city}
                        helperText={cityHelperText}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        inputProps={{}}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="postalCode"
                        name="postalCode"
                        label="ZIP CODE"
                        type="text"
                        className="input-field-styling required-field"
                        onChange={handleInputChange}
                        value={postalCode}
                        // error={postalCodeError}
                        helperText={postalCodeHelperText}
                        InputLabelProps={{
                          className: 'form-field-label required-label'
                        }}
                        inputProps={{ maxLength: 5 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="locationUrl"
                        autocomplete="LOCATION URL"
                        label="LOCATION URL"
                        className="location-field input-field-styling"
                        value={locationUrl}
                        helperText={locationUrlHelperText}
                        onChange={handleInputChange}
                        placeholder="Location URL"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee edit-address-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="facebookUrl"
                        autocomplete="FACEBOOK URL"
                        label="FACEBOOK URL"
                        className="location-field input-field-styling"
                        value={facebookUrl}
                        // error={Boolean(manufacturerUrlError)}
                        helperText={facebookUrlHelperText}
                        onChange={handleInputChange}
                        placeholder="Facebook URL"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee edit-address-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={12} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name="instagramHandle"
                        autocomplete="INSTAGRAM"
                        label="INSTAGRAM"
                        className="location-field input-field-styling"
                        value={instagramHandle}
                        // error={Boolean(manufacturerUrlError)}
                        helperText={instagramHandleHelperText}
                        onChange={handleInputChange}
                        placeholder="Instagram"
                        InputLabelProps={{
                          className:
                            'label-style-create-franchisee edit-address-label'
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>

          <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleClose('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              {isCreated ? '' : 'Discard Changes?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                  {!isCreated ? (
                    <Typography className="title-text-sub">
                      You have unsaved changes.
                      <Typography className="title-text-sub">
                        What do you want to do?
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography className="title-text-sub">
                      Location has been created successfully!
                    </Typography>
                  )}
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() => handleCloseConfirm('Discard')}
              >
                {isCreated ? 'Close' : 'Discard Changes'}
              </Button>
              {!isCreated && <Divider className="vertical-divider" />}
              {!isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleCloseConfirm('Continue')}
                >
                  Go Back to Editing
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
    </>
  );
};

export default AddLocation;
