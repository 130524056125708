import React, { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import moment from 'moment';

export const CohortDatePicker = ({
  onChange,
  label,
  fieldValue,
  disabled,
  disablePast
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleDateChange = (date) => {
    if (date) {
      const isoDate = moment(date).startOf('day').toISOString();
      setSelectedDate(date);
      onChange(isoDate);
    }
  };

  useEffect(() => {
    if (fieldValue) {
      const momentDate = moment(fieldValue);
      setSelectedDate(momentDate.toDate());
    }
    return () => {
      setSelectedDate(null);
    };
  }, [fieldValue]);

  const handleInputChange = (event) => {
    if (!disabled) {
      event.preventDefault();
      setOpen(true);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        value={selectedDate}
        disablePast={disablePast}
        onChange={handleDateChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            sx={{ width: '100%' }}
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true
            }}
            onClick={handleInputChange}
          />
        )}
        inputProps={{ readOnly: true }}
        open={open}
        onClose={() => setOpen(false)}
      />
    </LocalizationProvider>
  );
};
