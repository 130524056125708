import { ApiService } from 'src/services/ApiService';
import { getLocalData } from 'src/utils';

export default function useCreateCohorts() {
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');
  const UserName = getLocalData('UserName');
  const getAllAttribute = async ({ setAttributes }) => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAttributeFilter?locationId=${encodedLocationId}`
      );
      if (!response.error) {
        setAttributes(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createPayload = ({ orConditions, andConditions, attributes }) => {
    const orData = orConditions?.map((item) => {
      const matchedItem = attributes?.find(
        (nestedItem) => nestedItem?.field === item?.field
      );
      return {
        ...matchedItem,
        operator: item?.operator,
        value: item?.value
      };
    });
    const andData = andConditions?.map((item) => {
      const matchedItem = attributes?.find(
        (nestedItem) => nestedItem?.field === item?.field
      );
      return {
        ...matchedItem,
        operator: item?.operator,
        value: item?.value
      };
    });
    return [
      {
        condition: 'or',
        rules: orData
      },
      {
        condition: 'and',
        rules: andData
      }
    ];
  };

  const validateConditions = ({
    orConditions,
    andConditions,
    attributes,
    cohortName,
    setClientList,
    setIsLoading,
    setAllError,
    setAnyError,
    setShowSuccessMessage,
    setSuccessMessage,
    setShowMessage,
    setErrorMessage,
    type,
    cohortId
  }) => {
    const checkConditions = (conditions, setError) => {
      return conditions?.some((condition) => {
        if (
          conditions?.length > 0 &&
          (!condition.field || !condition.operator || !condition.value)
        ) {
          setError('Missing or empty field, operator, or value.');
          return true;
        }
        return false;
      });
    };

    // if (!orConditions?.length > 0 && !andConditions?.length > 0) return null;
    const hasOrError = checkConditions(orConditions, setAnyError);
    const hasAndError = checkConditions(andConditions, setAllError);
    if (!hasOrError && !hasAndError) {
      const segmentPayload = createPayload({
        orConditions,
        andConditions,
        attributes
      });
      if (type === 'createCohort') {
        CreateCohorts({
          cohortName,
          segmentPayload,
          setShowSuccessMessage,
          setSuccessMessage,
          setShowMessage,
          setErrorMessage,
          setIsLoading
        });
      } else if (type === 'updateCohort') {
        UpdateCohorts({
          cohortName,
          segmentPayload,
          cohortId,
          setShowSuccessMessage,
          setSuccessMessage,
          setShowMessage,
          setErrorMessage,
          setIsLoading
        });
      } else {
        getCohortClient({
          segmentPayload,
          setClientList,
          setIsLoading
        });
      }
    }
    return hasOrError || hasAndError;
  };

  const getCohortClient = async ({
    segmentPayload,
    setClientList,
    setIsLoading
  }) => {
    // Filter out segments where 'rules' is empty
    const filteredSegmentPayload = segmentPayload.filter(
      (segment) => segment.rules && segment.rules.length > 0
    );

    const data = {
      locationId: locationId,
      franchiseeId: franchiseeId,
      Segments: filteredSegmentPayload
    };

    console.log(data, 'data for get clients is consoled here');

    let encodedLocationId = encodeURIComponent(locationId);
    setIsLoading(true);
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getCohortClient?locationId=${encodedLocationId}`,
        data
      );

      if (!response.error) {
        setClientList(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setClientList([]);
      console.log(error);
    }
  };

  const checkCohortNameExist = async ({
    cohortName,
    setDisableSubmit,
    setCohortNameError
  }) => {
    setDisableSubmit(true);
    let templateNameCheck = cohortName;
    const data = {
      Name: templateNameCheck || ''
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/isExistingCohort`,
        data
      );

      if (response.statusCode && !response.error) {
        if (!response?.data?.isExists) {
          setDisableSubmit(false);
          setCohortNameError('');
        } else {
          setDisableSubmit(false);
          setCohortNameError(
            'Cohort name already exists.Please choose unique name.'
          );
        }
      }
    } catch (error) {
      setDisableSubmit(false);
    }
  };

  const CreateCohorts = async ({
    cohortName,
    segmentPayload,
    setShowSuccessMessage,
    setSuccessMessage,
    setShowMessage,
    setErrorMessage,
    setIsLoading
  }) => {
    try {
      setIsLoading(true);

      // Filter out segments where 'rules' is empty
      const filteredSegmentPayload = segmentPayload.filter(
        (segment) => segment.rules && segment.rules.length > 0
      );

      let data = {
        locationId: locationId,
        franchiseeId: franchiseeId,
        Name: cohortName,
        createdBy: UserName,
        Segments: filteredSegmentPayload
      };

      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/duplicateCohorts`,
        data
      );

      if (response.statusCode === 200) {
        if (response.error === false) {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setSuccessMessage('Cohort created successfully');
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
    }
  };

  const UpdateCohorts = async ({
    cohortName,
    segmentPayload,
    cohortId,
    setShowSuccessMessage,
    setSuccessMessage,
    setShowMessage,
    setErrorMessage,
    setIsLoading
  }) => {
    let encodedCohortId = encodeURIComponent(cohortId);
    setIsLoading(true);

    // Filter out segments where 'rules' is empty
    const filteredSegmentPayload = segmentPayload.filter(
      (segment) => segment.rules && segment.rules.length > 0
    );

    let data = {
      franchiseeId: franchiseeId,
      Name: cohortName,
      updatedBy: UserName,
      Segments: filteredSegmentPayload
    };

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/updateFranchiseeCohort?cohortId=${encodedCohortId}`,
        data
      );

      if (response.statusCode === 200) {
        if (response.error === false) {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setSuccessMessage('Cohort updated successfully');
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
    }
  };

  return {
    getAllAttribute,
    validateConditions,
    checkCohortNameExist,
    createPayload
  };
}
