import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Card
} from '@mui/material';
import './index.css';
import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

import Loader from '../../../components/Loader';
import { CohortClientTable } from './CohortClientTable';
import moment from 'moment';
import ConditionBuilder from './ConditionBuilder';
import useCreateCohorts from './Functions/CreateCohortsFunctions';
import useEditCohorts from './Functions/EditCohortsFunctions';

// LocationDetails page style wrapper
const ServiceDetailsWrapper = styled(Container)(
  ({ theme }) => `
          margin-top: ${theme.spacing(2)};
  `
);

export const CohortsDetail = (props) => {
  const { validateConditions, getAllAttribute } = useCreateCohorts();
  const { getCohortDetail } = useEditCohorts();
  const { selectedRow, setShowScreen, setIsDuplicate } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [attributes, setAttributes] = useState([]);

  const [cohortData, setCohortData] = useState();
  const [orConditions, setOrConditions] = useState([
    { id: 1, field: '', operator: '', value: '' }
  ]);
  const [andConditions, setAndConditions] = useState([
    { id: 1, field: '', operator: '', value: '' }
  ]);

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.(date).format('MMM DD, YYYY') : '';
    return utc;
  };

  const handleResetStates = () => {
    setShowScreen(0);
  };

  const handleOpenScreen = () => {
    setIsDuplicate(true);
    setShowScreen(2);
  };
  const handleOpenScreenEdit = () => {
    setShowScreen(3);
  };

  useEffect(() => {});

  useEffect(() => {
    getAllAttribute({ setAttributes });
  }, []);

  useEffect(() => {
    const cohortId = selectedRow?.sortKey;
    const partitionKey = selectedRow?.partitionKey
    if (cohortId && partitionKey) {
      getCohortDetail({
        cohortId,
        partitionKey,
        setCohortData,
        setIsLoading,
        setAndConditions,
        setOrConditions
      });
    }
  }, [selectedRow]);

  console.log(selectedRow, "selected row is consoled ere")

  return (
    <>
      <Loader IsLoading={isLoading} />
      <div className="custom-summary">
        <ServiceDetailsWrapper
          maxWidth={'100%'}
          className="location-details-wrapper p-0"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleResetStates()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>

                <Typography className="header-blue-32">
                  {selectedRow?.Name || cohortData?.Name}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid>
                <Button
                  className="cohorts-outlined-btn"
                  variant="outlined"
                  onClick={() => handleOpenScreen()}
                >
                  Duplicate
                </Button>
                {selectedRow && selectedRow.createdBy !== 'Franchisor' && (
                  <Button
                    sx={{ ml: '10px' }}
                    variant="contained"
                    onClick={() => handleOpenScreenEdit()}
                    className="btn-primary edit-button template-edit-btn"
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Box className="location-details-container" mt={5} ml={3}>
            {/* <Grid container spacing={2} > */}
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.4} className="location-details-col">
                <Box className="location-details-label summary-heading">
                  CREATED BY
                </Box>
              </Grid>
              <Grid item xs={12} sm={4.6} className="location-details-col">
                <Box className="location-details-value summary-value">
                  {selectedRow?.createdBy}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1.4} className="location-details-col">
                <Box className="location-details-label summary-heading">
                  CREATED ON
                </Box>
              </Grid>
              <Grid item xs={12} sm={4.6} className="location-details-col">
                <Box className="location-details-value summary-value">
                  {dateFormate(selectedRow?.CreationDate)}
                </Box>
              </Grid>
            </Grid>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={1.4} className="location-details-col">
                <Box className="location-details-label summary-heading">
                  LAST UPDATED ON
                </Box>
              </Grid>
              <Grid item xs={12} sm={4.6} className="location-details-col">
                <Box className="location-details-value summary-value">
                  {dateFormate(selectedRow?.LastModifiedDate)}
                </Box>
              </Grid>
              {selectedRow?.updatedBy && (
                <>
                  <Grid item xs={12} sm={1.4} className="location-details-col">
                    <Box className="location-details-label summary-heading">
                      LAST UPDATED BY
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4.6} className="location-details-col">
                    <Box className="location-details-value summary-value">
                      {selectedRow?.updatedBy}
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <Card
            sx={{
              padding: '15px',
              borderRadius: '19px 19px 0px 0px',
              mt: 5
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              ml={2}
            >
              <Grid item>
                <Typography className="table-heade header-blue-20">
                  {' '}
                  Criteria
                </Typography>
              </Grid>
            </Grid>
            <ConditionBuilder
              conditionName="or"
              conditionValues={orConditions}
              setConditionValues={setOrConditions}
              attributes={attributes}
              disableFields
            />
          </Card>

          <Typography
            textAlign="center"
            className="table-heade header-blue-22"
            my={4}
          >
            {'- AND -'}
          </Typography>

          <Card
            sx={{
              padding: '15px',
              borderRadius: '19px 19px 0px 0px',
              mt: 5
            }}
          >
            <ConditionBuilder
              conditionName="and"
              conditionValues={andConditions}
              setConditionValues={setAndConditions}
              attributes={attributes}
              disableFields
            />
          </Card>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="right"
            gap={2}
            mt={5}
          >
            <Grid item mb={3}>
              <Button
                onClick={() =>
                  validateConditions({
                    orConditions,
                    andConditions,
                    attributes,
                    setClientList,
                    setIsLoading,
                    type: 'getClients'
                  })
                }
                variant="contained"
              >
                View Eligible Clients
              </Button>
            </Grid>
          </Grid>
          <CohortClientTable tableData={clientList} />
        </ServiceDetailsWrapper>
      </div>
    </>
  );
};
